/* eslint react/no-unknown-property: 0 */
import { useEffect, useMemo, useRef } from "react"
import { DoubleSide, Group, Material, Mesh, MeshBasicMaterial, MeshPhongMaterial, MeshStandardMaterial, MultiplyOperation, Texture } from "three"
import { GLTF } from "three-stdlib"
import { useGLTF } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"

const mat = new MeshBasicMaterial({
	reflectivity: 1,
	side: DoubleSide
})
const otherMat = new MeshPhongMaterial({
	color: "#000000",
	specular: "#ffffff",
	reflectivity: 1,
	shininess: 1,
	combine: MultiplyOperation,
	side: DoubleSide
})
const brakeMat = new MeshPhongMaterial({
	color: "#660000",
	specular: "#ffffff",
	reflectivity: 0.5,
	combine: MultiplyOperation,
	side: DoubleSide
})
const licenseMat = brakeMat.clone()
licenseMat.color.set("#040404")

const envMapEnabledMaterials = [ mat, otherMat, brakeMat, licenseMat ]

// const materialsToSwap = {
// 	"Glass": mat,
// 	"Material": otherMat,
// 	"Material.002": otherMat,
// 	"Material.007": brakeMat,
// 	"Material.008": licenseMat
// }

// const objectsToMirror = [
// 	"Top_T",
// 	"Back_Glass_frame"
// ]

type GLTFResult = GLTF & {
  nodes: {
    Back_Body: Mesh
    Back_Glass_Planes: Mesh
    Plane010: Mesh
    Plane010_1: Mesh
    Plane010_2: Mesh
    Plane010_3: Mesh
    Plane010_4: Mesh
    Plane010_5: Mesh
    Lower_Front_Body: Mesh
    Front_Grille: Mesh
    Grill: Mesh
    DMCLogo: Mesh
    Trunk: Mesh
    Tubos_de_escape: Mesh
    Cube: Mesh
    Cube_1: Mesh
    LightGlass: Mesh
    Tire_Front: Mesh
    Tire_Back: Mesh
    Plane013: Mesh
    Plane013_1: Mesh
    OrangeLights: Mesh
    Cylinder: Mesh
    Cylinder001: Mesh
  }
  materials: {
    ["Material.001"]: MeshStandardMaterial
    ["Material.002"]: MeshStandardMaterial
    ["Material.007"]: MeshStandardMaterial
    ["Material.008"]: MeshStandardMaterial
    ["Material.011"]: MeshStandardMaterial
    ["Material.012"]: MeshStandardMaterial
    ["Material.013"]: MeshStandardMaterial
    ["Material.006"]: MeshStandardMaterial
    ["Material.003"]: MeshStandardMaterial
    ["Material.005"]: MeshStandardMaterial
    ["Material.004"]: MeshStandardMaterial
    ["Material.009"]: MeshStandardMaterial
    Glass: MeshStandardMaterial
    ["Material.014"]: MeshStandardMaterial
    ["Material.015"]: MeshStandardMaterial
  }
}

type DeLoreanProps = JSX.IntrinsicElements[ "group" ] & {
	stencil?: any,
	envMap: Texture,
	speed?: number
}

export function DeLorean({ stencil, envMap, speed = 10, ...props }: DeLoreanProps) {
	const { nodes, materials: mats } = useGLTF("/models/delorean2.glb") as GLTFResult

	const materials = useMemo(() => Object.entries(mats).reduce((obj, [ name, mat ]) => {
		obj[ name ] = mat.clone()
		return obj
	}, {} as Record<string, Material>), [ mats ])
	const clonedCustomMats = useMemo(() => envMapEnabledMaterials.map(mat => mat.clone()), [])

	useEffect(() => {
		Object.values(materials).forEach(mat => {
			Object.assign(mat, stencil)
			mat.needsUpdate = true
		})
		clonedCustomMats.forEach(mat => {
			Object.assign(mat, stencil)
			mat.needsUpdate = true
		})
	}, [ stencil, materials, clonedCustomMats ])

	useEffect(() => {
		clonedCustomMats.forEach(mat => {
			mat.envMap = envMap
			mat.needsUpdate = true
		})
	}, [ envMap, clonedCustomMats ])

	const wheels = useRef<{ wheelF?: Group, wheelB?: Group }>({})

	useFrame((_, delta) => {
		Object.values(wheels.current).forEach(obj => obj && (obj.rotation.x += speed * delta))
	})

	return (
		<group {...props} dispose={null}>
			<mesh geometry={nodes.Back_Body.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
			<mesh geometry={nodes.Back_Glass_Planes.geometry} material={materials["Material.001"]} position={[0, 1.46, -1.22]} rotation={[-0.19, 0, 0]} scale={[0.71, 1, 0.11]} />
			<group position={[0, 0.03, 2.82]}>
				<mesh geometry={nodes.Plane010.geometry} material={materials["Material.001"]} />
				<mesh geometry={nodes.Plane010_1.geometry} material={clonedCustomMats[2]} />
				<mesh geometry={nodes.Plane010_2.geometry} material={clonedCustomMats[3]} />
				<mesh geometry={nodes.Plane010_3.geometry} material={materials["Material.011"]} />
				<mesh geometry={nodes.Plane010_4.geometry} material={materials["Material.012"]} />
				<mesh geometry={nodes.Plane010_5.geometry} material={materials["Material.013"]} />
			</group>
			<mesh geometry={nodes.Lower_Front_Body.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
			<mesh geometry={nodes.Front_Grille.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.74]} />
			<mesh geometry={nodes.Grill.geometry} material={clonedCustomMats[1]} position={[0, 0.9, 2.54]} rotation={[-0.22, 0, 0]} />
			<mesh geometry={nodes.DMCLogo.geometry} material={materials["Material.005"]} position={[0, 0.84, 2.57]} rotation={[1.17, 0, 0]} scale={[0.07, 0.07, 0.02]} />
			<mesh geometry={nodes.Trunk.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
			<mesh geometry={nodes.Tubos_de_escape.geometry} material={materials["Material.004"]} position={[0, 0.04, 2.73]} />
			<group position={[0, 0.03, 2.73]}>
				<mesh geometry={nodes.Cube.geometry} material={clonedCustomMats[1]} />
				<mesh geometry={nodes.Cube_1.geometry} material={materials["Material.009"]} />
			</group>
			<mesh geometry={nodes.LightGlass.geometry} material={clonedCustomMats[0]} position={[0, 0.03, 2.73]} rotation={[Math.PI / 2, 0, 0]} />
			<group position={[0, -0.02, 2.7]}>
				<mesh geometry={nodes.Plane013.geometry} material={clonedCustomMats[0]} />
				<mesh geometry={nodes.Plane013_1.geometry} material={materials["Material.004"]} />
			</group>
			<mesh geometry={nodes.OrangeLights.geometry} material={materials["Material.015"]} position={[0, 0, 2.73]} rotation={[2.3, 0, 0]} scale={0.28} />
			<group ref={el => wheels.current.wheelF = el as any} position={[ 0, 0.44, 1.54 ]}>
				<mesh geometry={nodes.Cylinder001.geometry} material={materials["Material.009"]} position={[-1.15, 0, 0]} rotation={[0, 0, -Math.PI / 2]} scale={[0.94, 2.75, 0.94]} />
				<mesh geometry={nodes.Tire_Front.geometry} material={materials["Material.014"]} position={[0, -0.41, 1.19]} rotation={[0, 0, -Math.PI / 2]} />
			</group>
			<group ref={el => wheels.current.wheelB = el as any} position={[ 0, 0.44, -1.59 ]}>
				<mesh geometry={nodes.Cylinder.geometry} material={materials["Material.009"]} position={[-1.15, 0, 0 ]} rotation={[0, 0, -Math.PI / 2]} scale={[0.938, 2.75, 0.938]} />
				<mesh geometry={nodes.Tire_Back.geometry} material={materials["Material.014"]} position={[0, -0.4065, 4.321]} rotation={[0, 0, -Math.PI / 2]} />
			</group>
			{/* <mesh geometry={nodes.Cylinder.geometry} material={materials["Material.009"]} position={[-1.15, 0.44, -1.59]} rotation={[0, 0, -Math.PI / 2]} scale={[0.94, 2.75, 0.94]} /> */}
			{/* <mesh geometry={nodes.Cylinder001.geometry} material={materials["Material.009"]} position={[-1.15, 0.44, 1.54]} rotation={[0, 0, -Math.PI / 2]} scale={[0.94, 2.75, 0.94]} />
			<mesh geometry={nodes.Tire_Front.geometry} material={materials["Material.014"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} /> */}
			{/* <mesh geometry={nodes.Tire_Back.geometry} material={materials["Material.014"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} /> */}
		</group>
	)

	// return (
	// 	<group {...props} dispose={null}>
	// 		<mesh geometry={nodes.Back_Body.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.Mid_Lower_Part.geometry} material={materials["Material.006"]} position={[0, 0.04, 2.73]} />
	// 		<mesh geometry={nodes.Back_Glass_frame.geometry} material={clonedCustomMats[1]} position={[0, 0.04, 2.73]} scale={[1.01, 1, 1]} />
	// 		<mesh geometry={nodes.Back_Glass_frame.geometry} material={clonedCustomMats[1]} position={[0, 0.04, 2.73]} scale={[-1.01, 1, 1]} />
	// 		<mesh geometry={nodes.Back_Glass_Planes.geometry} material={materials["Material.001"]} position={[0, 1.46, -1.22]} rotation={[-0.19, 0, 0]} scale={[0.71, 1, 0.11]} />
	// 		<group position={[0, 0.03, 2.82]}>
	// 			<mesh geometry={nodes.Plane010.geometry} material={materials["Material.001"]} />
	// 			<mesh geometry={nodes.Plane010_1.geometry} material={clonedCustomMats[2]} />
	// 			<mesh geometry={nodes.Plane010_2.geometry} material={clonedCustomMats[3]} />
	// 			<mesh geometry={nodes.Plane010_3.geometry} material={materials["Material.011"]} />
	// 			<mesh geometry={nodes.Plane010_4.geometry} material={materials["Material.012"]} />
	// 			<mesh geometry={nodes.Plane010_5.geometry} material={materials["Material.013"]} />
	// 		</group>
	// 		<mesh geometry={nodes.Body_Front.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.Lower_Front_Body.geometry} material={materials["Material.006"]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.AntiWheelLight_Front.geometry} material={materials["Material.006"]} position={[0, 0.05, 2.73]} />
	// 		<mesh geometry={nodes.AntiWheelLight_Back.geometry} material={materials["Material.006"]} position={[0, 0.9, 2.54]} />
	// 		<mesh geometry={nodes.Doors.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.Front_Grille.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.74]} />
	// 		<mesh geometry={nodes.Front_Lights.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.74]} />
	// 		<mesh geometry={nodes.Grill.geometry} material={materials["Material.001"]} position={[0, 0.9, 2.54]} rotation={[-0.22, 0, 0]} />
	// 		<mesh geometry={nodes.DMCLogo.geometry} material={materials["Material.005"]} position={[0, 0.84, 2.57]} rotation={[1.17, 0, 0]} scale={[0.07, 0.07, 0.02]} />
	// 		<mesh geometry={nodes.Top_T.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.Top_T.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} scale={[-1,1,1]}/>
	// 		<mesh geometry={nodes.Trunk.geometry} material={clonedCustomMats[1]} position={[0, 0.03, 2.73]} />
	// 		<mesh geometry={nodes.Lower_trunk.geometry} material={materials["Material.006"]} position={[0, 0.04, 2.73]} />
	// 		<mesh geometry={nodes.Tubos_de_escape.geometry} material={materials["Material.003"]} position={[0, 0.04, 2.73]} />
	// 		<group position={[0, 0.03, 2.73]}>
	// 			<mesh geometry={nodes.Cube.geometry} material={materials["Material.001"]} />
	// 			<mesh geometry={nodes.Cube_1.geometry} material={materials["Material.004"]} />
	// 		</group>
	// 		<mesh geometry={nodes.LightGlass.geometry} material={clonedCustomMats[0]} position={[0, 0.03, 2.73]} rotation={[Math.PI / 2, 0, 0]} />
	// 		<mesh geometry={nodes.Front_Wheel_Llanta.geometry} material={materials["Material.009"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} scale={[0.53, 1, 0.5]} />
	// 		<mesh geometry={nodes.Back_Wheel_Llanta.geometry} material={materials["Material.009"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} scale={[0.53, 1, 0.5]} />
	// 		<mesh geometry={nodes.Tire_Front.geometry} material={materials["Material.010"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} />
	// 		<mesh geometry={nodes.Tire_Back.geometry} material={materials["Material.010"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} />
	// 		<mesh geometry={nodes.WheelPlate.geometry} material={materials["Material.006"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} scale={1.01} />
	// 		<mesh geometry={nodes.WheelPlate_Back.geometry} material={materials["Material.006"]} position={[0, 0.03, 2.73]} rotation={[0, 0, -Math.PI / 2]} scale={1.01} />
	// 		<mesh geometry={nodes.Front_Glass.geometry} material={clonedCustomMats[0]} position={[0, -0.02, 2.7]} />
	// 		<mesh geometry={nodes.Back_Side_Glass.geometry} material={clonedCustomMats[0]} position={[0, 0.03, 2.73]} />
	// 		<group position={[0, 0.03, 2.73]}>
	// 			<mesh geometry={nodes.Plane015.geometry} material={materials["Material.003"]} />
	// 			<mesh geometry={nodes.Plane015_1.geometry} material={clonedCustomMats[0]} />
	// 		</group>
	// 		<mesh geometry={nodes.RightLight1.geometry} material={materials.Light1} position={[0.6, 0.82, 2.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.28} />
	// 		<mesh geometry={nodes.RightLight2.geometry} material={materials.Light1} position={[0.86, 0.82, 2.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.28} />
	// 		<mesh geometry={nodes.LeftLight1.geometry} material={materials.Light1} position={[-0.95, 0.82, 2.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.28} />
	// 		<mesh geometry={nodes.LeftLight2.geometry} material={materials.Light1} position={[-0.7, 0.82, 2.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.28} />
	// 		<mesh geometry={nodes.OrangeLights.geometry} material={materials["Material.015"]} position={[0, 0, 2.73]} rotation={[2.3, 0, 0]} scale={0.28} />
	// 	</group>
	// )
}

useGLTF.preload("/models/delorean2.glb")
