/* eslint react/no-unknown-property: 0 */
import { useEffect } from "react"
import { Material, Mesh, MeshStandardMaterial } from "three"
import { GLTF } from "three-stdlib"
import { useGLTF } from "@react-three/drei"

type GLTFResult = GLTF & {
  nodes: {
    sloup5_lp: Mesh
  }
  materials: {
    [ "column.001" ]: MeshStandardMaterial
  }
}

type ColumnProps = JSX.IntrinsicElements[ "group" ] & {
	material?: Material,
	stencil?: any
}

export function Column({ material, stencil, ...props }: ColumnProps) {
	const { nodes, materials } = useGLTF("/models/column-comp.glb") as GLTFResult

	useEffect(() => {
		Object.assign(materials[ "column.001" ], stencil)
		materials[ "column.001" ].needsUpdate = true
	}, [ materials, stencil ])

	return (
		<group {...props} dispose={null}>
			<group rotation={[ Math.PI / 2, 0, 0 ]} scale={0.01}>
				<mesh geometry={nodes.sloup5_lp.geometry} material={material || materials[ "column.001" ]} />
			</group>
		</group>
	)
}

useGLTF.preload("/models/column-comp.glb")
