/* eslint react/no-unknown-property: 0 */
import { MeshBasicMaterial } from "three"
import { Box, Mask, useMask } from "@react-three/drei"

const borderMat = new MeshBasicMaterial({ color: "black" })

interface PortalProps {
	stencilId: number,
	size: number,
	position?: [ number, number, number ],
	rotation?: [ number, number, number ],
	children: (stencil: any) => JSX.Element
}

export function Portal({ stencilId, size, position, rotation, children }: PortalProps) {
	const stencil = useMask(stencilId, false)

	return (
		<group>
			<group>
				{children(stencil)}
			</group>
			<group
				position={position}
				rotation={rotation}>
				<Mask
					id={stencilId}
					colorWrite
					depthWrite={false}>
					<planeGeometry args={[ size, size ]}/>
				</Mask>
				<Box
					args={[ 0.05, size, 0.05 ]}
					position={[ -size / 2, 0, 0 ]}
					material={borderMat}
				/>
				<Box
					args={[ 0.05, size, 0.05 ]}
					position={[ size / 2, 0, 0 ]}
					material={borderMat}
				/>
				<Box
					args={[ 0.05, size, 0.05 ]}
					position={[ 0, -size / 2, 0 ]}
					rotation={[ 0, 0, Math.PI / 2 ]}
					material={borderMat}
				/>
				<Box
					args={[ 0.05, size, 0.05 ]}
					position={[ 0, size / 2, 0 ]}
					rotation={[ 0, 0, Math.PI / 2 ]}
					material={borderMat}
				/>
			</group>
		</group>
	)
}