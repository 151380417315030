/* eslint react/no-unknown-property: 0 */
import { useEffect } from "react"
import { Mesh, MeshStandardMaterial, Vector3 } from "three"
import { GLTF } from "three-stdlib"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { BoxProps, useCompoundBody } from "@react-three/cannon"

import { StencilProps } from "../../../types"

type GLTFResult = GLTF & {
	nodes: {
    Cube003: Mesh
    Cube003_1: Mesh
    Cube003_2: Mesh
    Cube003_3: Mesh
    Plane: Mesh
    Plane_1: Mesh
    Plane_2: Mesh
    Plane_3: Mesh
    Plane_4: Mesh
    Plane_5: Mesh
    flopy: Mesh
    Cube: Mesh
    flopy001: Mesh
    flopy002: Mesh
    vent_monitor: Mesh
    Plane002: Mesh
    Plane002_1: Mesh
    Plane002_2: Mesh
    Plane001_1: Mesh
    cpu001: Mesh
  }
  materials: {
    gray: MeshStandardMaterial
    ["white.001"]: MeshStandardMaterial
    ["vintage 2"]: MeshStandardMaterial
    black: MeshStandardMaterial
    white: MeshStandardMaterial
    ["gray metallic"]: MeshStandardMaterial
    metallic: MeshStandardMaterial
    green: MeshStandardMaterial
    ["gray stiker"]: MeshStandardMaterial
    ["vintage 1"]: MeshStandardMaterial
    screen: MeshStandardMaterial
  }
}

const vec = new Vector3()

type Props = JSX.IntrinsicElements["group"] & BoxProps & {
	speed?: number,
	stencil?: StencilProps
}

export function PC({ speed = 5, stencil, ...props }: Props) {
	const { nodes, materials } = useGLTF("/models/pc.glb") as GLTFResult

	// const randomPos = useRef(new Vector3().randomDirection())

	useEffect(() => {
		Object.values(materials).forEach(mat => {
			Object.assign(mat, stencil)
			mat.needsUpdate = true
		})
	}, [ materials, stencil ])

	const [ ref, api ] = useCompoundBody(() => ({
		...props,
		shapes: [
			{ type: "Box", args: [ 1, 0.4, 1.2 ], position: [ 0.15, 0.225, 0 ] },
			{ type: "Box", args: [ 0.75, 0.7, 0.9 ], position: [ 0.1, 0.8, 0 ] },
			{ type: "Box", args: [ 0.51, 0.12, 1.2 ], position: [ 1.05, 0.1, 0 ] }
		]
	}))

	useFrame((state) => {
		if (!ref.current) return
		vec.setFromMatrixPosition(ref.current.matrix)
		api.velocity.set(((state.mouse.x * state.viewport.width) / 2 - vec.x) * speed, ((state.mouse.y * state.viewport.height) / 2 - vec.y) * speed, -vec.z)
		// vec.setFromMatrixPosition(ref.current.matrix).sub(randomPos.current).normalize().multiplyScalar(-400)
		// api.applyForce(vec.toArray(), [ 0, 0, 0 ])
		// if (Math.random() < 0.1) {
		// 	randomPos.current.randomDirection()
		// 	console.log("move")
		// }
	})

	return (
		<group
			{...props}
			ref={ref as any}
			dispose={null}>
			<group position={[0, 2.22, 0]}>
				<mesh geometry={nodes.Cube003.geometry} material={materials.gray} castShadow/>
				<mesh geometry={nodes.Cube003_1.geometry} material={materials["white.001"]} castShadow/>
				<mesh geometry={nodes.Cube003_2.geometry} material={materials["vintage 2"]} castShadow/>
				<mesh geometry={nodes.Cube003_3.geometry} material={materials.black} castShadow/>
			</group>
			<group position={[0.12, 0.26, -0.1]}>
				<mesh geometry={nodes.Plane.geometry} material={materials.gray} castShadow/>
				<mesh geometry={nodes.Plane_1.geometry} material={materials.white} />
				<mesh geometry={nodes.Plane_2.geometry} material={materials["gray metallic"]} castShadow/>
				<mesh geometry={nodes.Plane_3.geometry} material={materials.metallic} castShadow/>
				<mesh geometry={nodes.Plane_4.geometry} material={materials.green} castShadow/>
				<mesh geometry={nodes.Plane_5.geometry} material={materials["gray stiker"]} castShadow/>
			</group>
			<mesh geometry={nodes.flopy.geometry} material={materials["vintage 1"]} position={[0.82, 0.27, -0.1]} castShadow/>
			<mesh geometry={nodes.Cube.geometry} material={materials.screen} position={[0.76, 2.23, -0.89]} rotation={[0, 0, 0.08]} scale={[0.68, 0.63, 0.71]} castShadow/>
			<mesh geometry={nodes.flopy001.geometry} material={materials["vintage 1"]} position={[0.82, 0.27, -0.1]} castShadow/>
			<mesh geometry={nodes.flopy002.geometry} material={materials["vintage 1"]} position={[0.82, 0.27, -0.1]} castShadow/>
			<mesh geometry={nodes.vent_monitor.geometry} material={materials.gray} position={[0, 2.22, 0]} castShadow/>
			<group position={[3.15, 0.03, 0.11]} rotation={[0, 0, -0.22]} scale={[1, 1, 1.79]}>
				<mesh geometry={nodes.Plane002.geometry} material={materials["vintage 2"]} castShadow/>
				<mesh geometry={nodes.Plane002_1.geometry} material={materials.white} castShadow/>
				<mesh geometry={nodes.Plane002_2.geometry} material={materials["white.001"]} castShadow/>
				<mesh geometry={nodes.Plane001_1.geometry} material={materials["vintage 2"]} position={[-0.47, 0.22, 0.85]} scale={0.11} castShadow/>
			</group>
			<mesh geometry={nodes.cpu001.geometry} material={materials["gray metallic"]} position={[0.12, 0.26, -0.1]} castShadow/>
		</group>
	)
}

useGLTF.preload("/models/pc.glb")
