import { ReactNode, useState } from "react"
import { Euler, Material, Vector3 } from "three"
import { Triplet } from "@pmndrs/cannon-worker-api"
import { Text3D, useCursor } from "@react-three/drei"

type LinkProps = {
	position?: Triplet | Vector3,
	rotation?: Triplet | Euler,
	scale?: Triplet | Vector3 | number,
	visible?: boolean,
	materials: [ Material, Material ],
	font?: string,
	text: string,
	link: string | (() => void),
	children?: (hovered: boolean) => JSX.Element | ReactNode | ReactNode[]
}

export function Link({ text, link, materials, font = "/Teletactile_Regular.json", visible = true, children, ...props }: LinkProps) {
	const [ hovered, setHovered ] = useState(false)

	useCursor(hovered)

	if (!visible) return null
	
	return (
		<Text3D
			font={font}
			{...props}
			onPointerOver={() => setHovered(true)}
			onPointerOut={() => setHovered(false)}
			onClick={() => typeof link === "string" ? window.open(link, "_blank"): link()}
			material={materials[ hovered ? 1: 0 ]}>
			{text}
			{children && children(hovered)}
		</Text3D>
	)
}