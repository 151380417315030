import { Color, ShaderMaterial, TextureLoader, Vector4 } from "three"
import { ShaderMaterialProps } from "@react-three/fiber"

const tempColor = new Color()
interface GradientProps {
	colors: string[],
	stops?: string[],
	direction?: "x" | "y" | "radial",
	center?: [ number, number ]
}
export const createGradientShader = ({ colors, stops, direction = "y", center = [ 0.5, 0.5 ] }: GradientProps) => {
	const uniforms: Record<string, any> = {}
	const steps = !stops || !stops.length
		? colors.map((_, i, cArr) => (i / (cArr.length - 1)).toFixed(3))
		: stops

	colors.forEach((c, i) => {
		const [ color, opacity = "1" ] = c.split(" ")
		tempColor.set(color)
		uniforms[ "color" + i ] = {
			value: new Vector4(tempColor.r, tempColor.g, tempColor.b, parseFloat(opacity))
		}
	})

	let mixString
	switch(direction) {
		case "x":
		case "y":
			mixString = `vUv.${direction}`
			break
		case "radial":
			mixString = `clamp(distance(vUv, vec2(${center.join(", ")})), 0.0, 1.0)`
			break
		default:
			console.warn(`Invalid gradient direction: ${direction}, defaulting to "x"`)
			mixString = "vUv.x"
			break
	}

	return new ShaderMaterial({
		uniforms,
		vertexShader: `
		varying vec2 vUv;
		
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		}
		`,
		fragmentShader: colors.length === 2
			? `
				uniform vec4 color0;
				uniform vec4 color1;
				varying vec2 vUv;
				void main() {
					gl_FragColor = vec4(mix(color0, color1, ${mixString}));
				}
			`
			:`
				${Object.keys(uniforms).map(u => `uniform vec4 ${u};`).join("\n")}

				varying vec2 vUv;

				void main() {
					float mixValue = ${mixString};
					if (mixValue < ${steps[1]}) {
						gl_FragColor = vec4(mix(color0, color1, mixValue * ${(1 / parseFloat(steps[1])).toFixed(3)}));
					}
					${steps.slice(1, steps.length - 3).map((step, i) => `
						else if (mixValue < ${steps[ i + 1 ]}) {
							gl_FragColor = vec4(mix(color${i}, color${i + 1}, (mixValue - ${step}) * ${(1 / (parseFloat(steps[ i + 1 ]) - parseFloat(step))).toFixed(3)}));
						}
					`).join("\n")}
					else {
						gl_FragColor = vec4(mix(color${colors.length - 2}, color${colors.length - 1}, (mixValue - ${steps[ steps.length - 2 ]}) * ${(1 / (parseFloat(steps[ steps.length - 1 ]) - parseFloat(steps[ steps.length - 2 ]))).toFixed(3)}));
					}
				}
			`,
		transparent: true
	})
}

export const createCheckerboardShader = ({ x = 10, y = 10 }) => {
	return new ShaderMaterial({
		uniforms: {
			x: { value: x },
			y: { value: y }
		},
		vertexShader: `
		varying vec2 vUv;

		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		}
		`,
		fragmentShader: `
		uniform float x;
		uniform float y;

		varying vec2 vUv;

		void main() {
			if (mod(vUv.x * x, 2.0) > 1.0) {
				gl_FragColor = vec4(vec3(floor(mod(vUv.y * y, 2.0))), 1.0);
			}
			else {
				gl_FragColor = vec4(vec3(1.0 - floor(mod(vUv.y * y, 2.0))), 1.0);
			}
		}
		`
	})
}

type NoiseProps = ShaderMaterialProps & {
	color: Color | Vector4,
	noise: string
}
export const createNoiseShader = ({ color, noise, ...props }: NoiseProps) => {
	return new ShaderMaterial({
		uniforms: {
			color: { value: color },
			noise: { value: new TextureLoader().load(process.env.PUBLIC_URL + noise) },
			iTime: { value: 0.0 }
		},
		vertexShader: `
		uniform sampler2D noise;
		uniform float iTime;

		varying vec2 vUv;

		void main() {
			vUv = uv;

			vec3 pos = position;
			pos.y -= mod(iTime * 200.0, 0.2);

			float f = (pos.y + 2.0) / 4.0;
			float y = mod(uv.y - 0.25 * mod(iTime * 200.0, 0.2) + iTime * 50.0, 1.0);
			float noiseHeight = 0.5 * (texture2D(noise, vec2(uv.x, y)).x + texture2D(noise, vec2(uv.x, 1.0 - y)).x);
			float yDist = distance(uv.y - 0.25 * mod(iTime * 200.0, 0.2), 0.5);

			if (distance(uv.x, 0.5) > 0.1875) {
				pos.z += 0.75 * noiseHeight * noiseHeight + 0.1;
				pos.z *= 1.5 * pow(max(1.0 - 2.3 * yDist, 0.0), 0.5);
				pos.z = mix(pos.z, position.z + 0.05 * noiseHeight, min(max(yDist - 0.4, 0.0), 0.1) * 10.0);
			}
			else {
				pos.z += 0.05 * noiseHeight;
			}
			gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
		}
		`,
		fragmentShader: `
		uniform vec4 color;
		uniform float iTime;

		varying vec2 vUv;

		void main() {
			if (vUv.y - 0.25 * mod(iTime * 200.0, 0.2) < 0.0) {
				gl_FragColor = vec4(0.0);
			}
			else {
				gl_FragColor = color;
			}
		}
		`,
		...props as any
	})
}