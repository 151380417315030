/* eslint react/no-unknown-property: 0 */
import { useMemo, useRef, useState } from "react"
import { CubeCamera, Group, HalfFloatType, Texture, WebGLCubeRenderTarget } from "three"
import { useFrame, useThree } from "@react-three/fiber"

import { StencilProps } from "../types"

type Props = Omit<JSX.IntrinsicElements[ "group" ], "children"> & {
	stencil?: StencilProps,
	frames?: number,
	resolution?: number,
	near?: number,
	far?: number,
	children: (texture: Texture) => JSX.Element
}

export function CustomCubeCamera({
	stencil,
	frames = Infinity,
	resolution = 1024,
	near = 0.1,
	far = 100,
	children,
	...props
}: Props) {
	const { scene, gl } = useThree()

	const target = useMemo(() => {
		const target = new WebGLCubeRenderTarget(resolution)
		target.texture.encoding = gl.outputEncoding
		target.texture.type = HalfFloatType
		return target
	}, [ resolution ])
	const [ camera, setCamera ] = useState<CubeCamera>()
	const [ group, setGroup ] = useState<Group>()

	const count = useRef(0)

	useFrame(() => {
		if (!camera || !group || count.current > frames) return

		const oldBackground = scene.background
		scene.background = null
		const hiddenObjs: string[] = []
		scene.traverse((obj: any) => {
			if (!obj.material) return
			if (obj.material.stencilRef !== (stencil?.stencilRef || 0)) {
				if (!obj.visible) hiddenObjs.push(obj.uuid)
				obj.visible = false
			}
		})
		group.visible = false
		camera.update(gl, scene)
		scene.traverse((obj: any) => {
			if (!obj.material) return
			if (hiddenObjs.includes(obj.uuid)) return
			obj.visible = true
		})
		group.visible = true
		scene.background = oldBackground
		count.current++
	}, -1)

	return (
		<group {...props}>
			<cubeCamera ref={setCamera as any} args={[ near, far, target ]}/>
			<group ref={setGroup as any}>{children(target.texture)}</group>
		</group>
	)
}