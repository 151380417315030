/* eslint react/no-unknown-property: 0 */
import { useEffect, useMemo, useRef } from "react"
import { Bone, Color, MeshStandardMaterial, SkinnedMesh } from "three"
import { GLTF } from "three-stdlib"
import { useCylinder } from "@react-three/cannon"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { cloneSkinnedMesh } from "../../../utils"

type GLTFResult = GLTF & {
	nodes: {
		Cylinder: SkinnedMesh
		Bone: Bone
	}
}

type StrawProps = JSX.IntrinsicElements[ "group" ] & {
	stencil?: any
}

export function Straw({ stencil, ...props }: StrawProps) {
	const { scene } = useGLTF("/models/straw.glb") as GLTFResult

	const clone = useMemo(() => cloneSkinnedMesh(scene), [ scene ])

	useEffect(() => {
		clone.scene.traverse((obj: any) => {
			if (obj.isSkinnedMesh) {
				const { bones } = obj.skeleton
				const bendAmt = Math.random()
				for (let i = 1; i < 7; i++) {
					bones[i].rotation.z = bendAmt * 30 * Math.PI / 180
				}
				bones[7].rotation.z = bendAmt * 5 * Math.PI / 180
			}
		})
	}, [ clone ])

	const material = useMemo(() => {
		const color = new Color()
		color.setHSL(Math.random(), 0.6, 0.5)
		const mat = new MeshStandardMaterial({ color })
		return mat
	}, [])

	useEffect(() => {
		clone.scene.traverse((obj: any) => {
			if (obj.isMesh) obj.material = material
		})
	}, [ material, clone ])

	useEffect(() => {
		Object.assign(material, stencil)
		material.needsUpdate = true
	}, [ stencil, material ])

	const posRef = useRef([
		-1.5 + 3 * Math.random(),
		1 + 0.5 * Math.random(),
		-1.5 + 3 * Math.random()
	])
	const { current: rotRef } = useRef([
		2 * Math.PI * Math.random(),
		2 * Math.PI * Math.random(),
		2 * Math.PI * Math.random()
	])
	const [ ref, api ] = useCylinder(() => ({
		args: [ 0.075, 0.075, 2, 16 ],
		mass: 5,
		type: "Dynamic",
		position: posRef.current as any,
		rotation: rotRef as any,
		angularDamping: 0.5
	}))

	useEffect(() => api.position.subscribe(p => posRef.current = p), [ api.position ])

	useFrame(() => {
		if (posRef.current[1] < -3) {
			api.position.set(
				-1.5 + 3 * Math.random(),
				2.5 + 10 * Math.random(),
				-1.5 + 3 * Math.random()
			)
			api.velocity.set(0, 0, 0)
			api.angularVelocity.set(0, 0, 0)
		}
	})

	return (
		<group
			ref={ref as any}
			{...props}
			rotation={rotRef as any}
			dispose={null}>
			<primitive object={clone.scene} />
		</group>
	)
}

useGLTF.preload("/models/straw.glb")
