/* eslint react/no-unknown-property: 0 */
import { ReactNode, Suspense, useEffect, useState } from "react"
import { Camera } from "three"
import { Canvas } from "@react-three/fiber"
import { Environment, OrbitControls, OrbitControlsChangeEvent, PerspectiveCamera, Stats } from "@react-three/drei"

const DEBUG = process.env.NODE_ENV !== "production"

export interface Scene3DProps {
	loading?: JSX.Element | null,
	cameraPosition: [ number, number, number ],
	autoRotate?: boolean,
	enablePan?: boolean,
	enableZoom?: boolean,
	onOrbitChange?: (event: OrbitControlsChangeEvent) => void,
	children?: ReactNode | ReactNode[]
}

export function Scene({
	loading = null,
	cameraPosition,
	autoRotate = false,
	enablePan = true,
	enableZoom = true,
	onOrbitChange,
	children
}: Scene3DProps) {
	const [ camera, setCamera ] = useState<Camera>()

	const [ controls, setControls ] = useState()

	useEffect(() => {
		if (!controls || !onOrbitChange) return

		(controls as any).addEventListener("change", onOrbitChange)

		return () => (controls as any).removeEventListener("change", onOrbitChange)
	}, [ controls, onOrbitChange ])

	return (
		<Suspense fallback={loading}>
			<Canvas
				dpr={Math.min(window.devicePixelRatio, 3)}
				// dpr={1}
				flat={true}
				shadows>
				{DEBUG && <Stats/>}
				<Environment
					background={false}
					path="/"
					files="potsdamer_platz_1k.hdr"
				/>
				<OrbitControls
					ref={setControls as any}
					camera={camera}
					minDistance={2}
					maxDistance={20}
					autoRotate={autoRotate}
					enablePan={enablePan}
					enableZoom={enableZoom}
				/>
				<PerspectiveCamera
					ref={setCamera as any}
					makeDefault
					position={cameraPosition}
					fov={40}
					aspect={window.innerWidth / window.innerHeight}
					near={1}
					far={30}
				/>
				<ambientLight
					color="#bbb"
					intensity={0.8}
				/>
				<directionalLight
					color="#ef7800"
					intensity={1.2}
					position={[ 1, 10, -2 ]}
					castShadow={true}
					shadow-mapSize-width={512}
					shadow-mapSize-height={512}
					shadow-camera-far={30}
					shadow-camera-left={-10}
					shadow-camera-right={10}
					shadow-camera-top={10}
					shadow-camera-bottom={-10}
				/>
				{children}
			</Canvas>
		</Suspense>
	)
}