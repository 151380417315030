/* eslint react/no-unknown-property: 0 */
import { usePlane } from "@react-three/cannon"

export function Boundary() {
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ 0, 0, -2 ]
	}))
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ 0, 0, 2 ],
		rotation: [ 0, Math.PI, 0 ]
	}))
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ -2, 0, 0 ],
		rotation: [ 0, Math.PI / 2, 0 ]
	}))
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ 2, 0, 0 ],
		rotation: [ 0, -Math.PI / 2, 0 ]
	}))
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ 0, -2, 0 ],
		rotation: [ -Math.PI / 2, 0, 0 ]
	}))
	usePlane(() => ({
		type: "Static",
		args: [ 4, 4 ],
		position: [ 0, 2, 0 ],
		rotation: [ Math.PI / 2, 0, 0 ]
	}))

	return null
}