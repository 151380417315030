/* eslint react/no-unknown-property: 0 */
import { useEffect, useMemo } from "react"
import { Color, DoubleSide, MeshStandardMaterial, Mesh, Vector3 } from "three"
import { GLTF } from "three-stdlib"
import { useFrame } from "@react-three/fiber"
import { useGLTF } from "@react-three/drei"
import { BodyProps, useCompoundBody } from "@react-three/cannon"

import { StencilProps } from "../../../types"

type GLTFResult = GLTF & {
	nodes: {
		Cylinder: Mesh
	}
}

const vec = new Vector3()

type StrawProps = JSX.IntrinsicElements[ "group" ] & BodyProps & {
	stencil?: StencilProps
}

export function Straw({ stencil, position = [ 0, 0, 0 ], ...props }: StrawProps) {
	const { nodes } = useGLTF("/models/straw_static.glb") as GLTFResult

	const material = useMemo(() => {
		const color = new Color()
		color.setHSL(Math.random(), 0.6, 0.5)
		const mat = new MeshStandardMaterial({
			color,
			side: DoubleSide
		})
		return mat
	}, [])

	useEffect(() => {
		Object.assign(material, stencil)
		material.needsUpdate = true
	}, [ stencil, material ])

	const [ ref, api ] = useCompoundBody(() => ({
		...props,
		position,
		shapes: [
			{
				type: "Cylinder",
				args: [ 0.07, 0.07, 1.4 ]
			},
			{
				type: "Cylinder",
				args: [ 0.07, 0.07, 0.7 ],
				position: [ 0.1, 0.4, 0 ]
			}
		]
	}))

	useFrame(() => {
		if (!ref.current) return
		vec.setFromMatrixPosition(ref.current.matrix).normalize().multiplyScalar(-0.5)
		api.applyForce(vec.toArray(), [ 0, 0, 0 ])
	})

	return (
		<group
			{...props}
			ref={ref as any}
			position={position}
			dispose={null}>
			<mesh
				geometry={nodes.Cylinder.geometry}
				material={material}
				position={[ 0, 2.5, 0 ]}
				castShadow
			/>
		</group>
	)
}

useGLTF.preload("/models/straw_static.glb")
