/* eslint react/no-unknown-property: 0 */
import { Vector3 } from "three"
import { Plane } from "@react-three/drei"
import { Physics } from "@react-three/cannon"

import type { PortalSceneProps } from "../../../types"

import { Diorama } from "../../Diorama"
import { Boundary } from "./Boundary"
import { PC } from "./PC_Physics"
import { Straw } from "./Straw_Physics_simple"

const straws = Array.from({ length: 60 }, () => ({
	position: new Vector3().randomDirection().multiplyScalar(2).toArray(),
	rotation: new Vector3().randomDirection().multiplyScalar(2 * Math.PI).toArray()
}))

export function Strawputer({ stencil, enabled = true, ...props }: PortalSceneProps) {
	return (
		<group
			{...props}
			visible={enabled}>
			<Physics
				isPaused={!enabled}
				gravity={[ 0, 0, 0 ]}>
				<Diorama stencil={stencil}/>
				<Plane
					args={[ 4, 4 ]}
					position={[ 0, -2, 0 ]}
					rotation={[ -Math.PI / 2, 0, 0 ]}
					receiveShadow>
					<meshStandardMaterial
						color="black"
						{...stencil}
					/>
				</Plane>
				<Boundary/>
				<PC
					rotation={[ 0, -Math.PI / 2, 0 ]}
					scale={0.36}
					stencil={stencil}
					mass={40}
					angularDamping={0.9}
					linearDamping={0.5}
				/>
				{straws.map((transform, i) => (
					<Straw
						key={i}
						{...transform}
						scale={0.18}
						stencil={stencil}
						mass={1}
						angularDamping={0.4}
						linearDamping={0.8}
					/>
				))}
				{/* <Debug
					color="black"
					scale={1.05}>
					<Boundary/>
					<PC
						rotation={[ 0, -Math.PI / 2, 0 ]}
						scale={0.36}
						stencil={stencil}
						mass={40}
						angularDamping={0.9}
						linearDamping={0.5}
					/>
					{straws.map((transform, i) => (
						<Straw
							key={i}
							{...transform}
							scale={0.18}
							stencil={stencil}
							mass={1}
							angularDamping={0.4}
							linearDamping={0.8}
						/>
					))}
				</Debug> */}
			</Physics>
		</group>
	)
}