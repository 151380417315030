/* eslint react/no-unknown-property: 0 */
import { useCallback, useState } from "react"
import { Camera, Vector3 } from "three"
import { OrbitControlsChangeEvent } from "@react-three/drei"
import { Bloom, EffectComposer } from "@react-three/postprocessing"

import { StencilProps } from "../types"

import { Scene } from "./Scene"
import { PortalBox } from "./PortalBox"
import { Links, Outrun, Strawputer, Vaporwave } from "./scenes"

const vec = new Vector3()
export function Main() {
	const [ frontEnabled, setFrontEnabled ] = useState(true)
	const [ backEnabled, setBackEnabled ] = useState(true)
	const [ rightEnabled, setRightEnabled ] = useState(false)
	const [ leftEnabled, setLeftEnabled ] = useState(true)

	const onChange = useCallback((event: OrbitControlsChangeEvent) => {
		const camera: Camera = event.target.object
		camera.getWorldPosition(vec)
		setFrontEnabled(-vec.z <= Math.abs(vec.x))
		setBackEnabled(vec.z <= Math.abs(vec.x))
		setRightEnabled(-vec.x <= Math.abs(vec.z))
		setLeftEnabled(vec.x <= Math.abs(vec.z))
	}, [])

	return (
		<Scene
			cameraPosition={[ -10, 0, 0 ]}
			onOrbitChange={onChange}>
			<EffectComposer autoClear={false} stencilBuffer>
				<Bloom
					intensity={1}
					luminanceThreshold={0.2}
					luminanceSmoothing={0.5}
				/>
			</EffectComposer>
			<PortalBox scenes={{
				front: (stencil: StencilProps) => (
					<Vaporwave
						stencil={stencil}
						enabled={frontEnabled}
					/>
				),
				back: (stencil: StencilProps) => (
					<Strawputer
						stencil={stencil}
						rotation={[ 0, Math.PI, 0 ]}
						enabled={backEnabled}
					/>
				),
				right: (stencil: StencilProps) => (
					<Outrun
						stencil={stencil}
						rotation={[ 0, Math.PI / 2, 0 ]}
						enabled={rightEnabled}
					/>
				),
				left: (stencil: StencilProps) => (
					<Links
						stencil={stencil}
						rotation={[ 0, -Math.PI / 2, 0 ]}
						enabled={leftEnabled}
					/>
				)
			}}/>
		</Scene>
	)
}