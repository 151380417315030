import { Material, MeshStandardMaterial } from "three"
import { Plane } from "@react-three/drei"
import { useEffect, useMemo } from "react"

const mat = new MeshStandardMaterial({ color: "black" })

type DioramaProps = JSX.IntrinsicElements[ "group" ] & {
	dims?: [ number, number, number ],
	material?: Material,
	floorMat?: Material,
	bgMat?: Material,
	stencil?: any
}

export function Diorama({
	dims = [ 4, 4, 4 ],
	material,
	floorMat,
	bgMat,
	stencil,
	children,
	...props
}: DioramaProps) {
	const clonedMat = useMemo(() => (
		!material
			? mat.clone()
			: undefined
	), [ material ])

	useEffect(() => {
		if (!clonedMat) return
		Object.assign(clonedMat, stencil)
		clonedMat.needsUpdate = true
	}, [ stencil, clonedMat ])

	return (
		<group {...props}>
			<Plane
				args={[ dims[0], dims[1] ]}
				position={[ 0, 0, dims[2] / 2 ]}
				rotation={[ 0, Math.PI, 0 ]}
				material={bgMat || material || clonedMat}
			/>
			<Plane
				args={[ dims[0], dims[1] ]}
				position={[ 0, 0, -dims[2] / 2 ]}
				material={bgMat || material || clonedMat}
			/>
			<Plane
				args={[ dims[2], dims[1] ]}
				position={[ dims[0] / 2, 0, 0 ]}
				rotation={[ 0, -Math.PI / 2, 0 ]}
				material={material || clonedMat}
			/>
			<Plane
				args={[ dims[2], dims[1] ]}
				position={[ -dims[0] / 2, 0, 0 ]}
				rotation={[ 0, Math.PI / 2, 0 ]}
				material={material || clonedMat}
			/>
			<Plane
				args={[ dims[0], dims[2] ]}
				position={[ 0, -dims[1] / 2, 0 ]}
				rotation={[ -Math.PI / 2, 0, 0 ]}
				material={floorMat || material || clonedMat}
			/>
			<Plane
				args={[ dims[0], dims[2] ]}
				position={[ 0, dims[1] / 2, 0 ]}
				rotation={[ Math.PI / 2, 0, 0 ]}
				material={material || clonedMat}
			/>
			{children}
		</group>
	)
}