/* eslint react/no-unknown-property: 0 */
import { useEffect, useMemo } from "react"
import { FrontSide, Vector4 } from "three"
import { useFrame } from "@react-three/fiber"

import type { StencilProps } from "../../../types"
import { createNoiseShader } from "../../../utils"

const noiseMat = createNoiseShader({
	noise: "noise.png",
	color: new Vector4(0, 0, 0, 1),
	side: FrontSide,
	transparent: true
})
const noiseMat2 = createNoiseShader({
	noise: "noise.png",
	color: new Vector4(0.36, 0.1, 1, 1),
	side: FrontSide,
	transparent: true,
	wireframe: true
})

type TerrainProps = JSX.IntrinsicElements[ "group" ] & {
	stencil?: StencilProps,
	enabled?: boolean
}

export function Terrain({ stencil, enabled, ...props }: TerrainProps) {
	const clonedMats = useMemo(() => ([
		noiseMat.clone(),
		noiseMat2.clone()
	]), [])

	useFrame(({ clock }) => {
		if (!enabled) return
		clonedMats.forEach(mat => mat.uniforms.iTime.value = clock.getElapsedTime() / 100)
	})

	useEffect(() => {
		clonedMats.forEach(mat => {
			Object.assign(mat, stencil)
			mat.needsUpdate = true
		})
	}, [ stencil, clonedMats ])

	return (
		<group {...props}>
			<mesh
				material={clonedMats[0]}
				rotation={[ -Math.PI / 2, 0, 0 ]}>
				<planeGeometry args={[ 4, 4, 20, 20 ]}/>
			</mesh>
			<mesh
				material={clonedMats[1]}
				rotation={[ -Math.PI / 2, 0, 0 ]}>
				<planeGeometry args={[ 4, 4, 20, 20 ]}/>
			</mesh>
		</group>
	)
}