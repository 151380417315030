/* eslint react/no-unknown-property: 0 */
import { useEffect } from "react"
import { MeshBasicMaterial, MeshNormalMaterial, MeshStandardMaterial, Texture } from "three"
import { Billboard, Line, Plane, Sphere, useTexture } from "@react-three/drei"

import type { PortalSceneProps } from "../../../types"
import { createCheckerboardShader } from "../../../utils"

import { Bust } from "./Bust"
import { Column } from "./Column"
import { Palm } from "./Palm"
import { Diorama } from "../../../components/Diorama"
import { CustomCubeCamera } from "../../CustomCubeCamera"

const boxMat = new MeshStandardMaterial({ color: 0xbbbbbb })

const checkerMat = createCheckerboardShader({ x: 10, y: 10 })

const pinkPalmMat = new MeshBasicMaterial({
	color: "#ee9999",
	transparent: true,
	opacity: 0.6
})

const normalMat = new MeshNormalMaterial()

const palms = Array.from({ length: 6 }, (_, i) => ({
	posX: (1.25 - 0.25 * Math.floor(i / 2)) * (i % 2 ? -1: 1),
	rotY: i % 2
		? -Math.PI / 3
		: Math.PI / 6
}))

export function Vaporwave({ stencil, enabled = true, ...props }: PortalSceneProps) {
	const [ paintPng, bgMap ] = useTexture([ "/paint.png", "/bigwave.jpg" ])

	useEffect(() => {
		Object.assign(normalMat, stencil)
		normalMat.needsUpdate = true
		Object.assign(pinkPalmMat, stencil)
		pinkPalmMat.needsUpdate = true
		Object.assign(checkerMat, stencil)
		checkerMat.needsUpdate = true
		Object.assign(boxMat, stencil)
		boxMat.needsUpdate = true
	}, [ stencil ])
	
	return (
		<group {...props}>
			<group visible={enabled}>
				<Diorama
					material={boxMat}
					floorMat={checkerMat}
					stencil={stencil}
				/>
				<CustomCubeCamera
					frames={1}
					stencil={stencil}>
					{(texture: Texture) => (
						<Sphere args={[ 0.49, 64, 32 ]}>
							<meshBasicMaterial envMap={texture} {...stencil}/>
						</Sphere>
					)}
				</CustomCubeCamera>
				<Sphere args={[ 0.5, 32, 16 ]}>
					<meshStandardMaterial
						attach="material"
						color="#dddddd"
						roughness={0.2}
						metalness={1}
						transparent={true}
						opacity={0.35}
						{...stencil}
					/>
				</Sphere>
				<Line
					position={[ 0, 0, 0.25 ]}
					points={[
						[ -1, 0.5, -0.5 ],
						[ -0.1, 0.4, 0.25 ],
						[ 0.1, -0.4, 0.5 ],
						[ 1, 0, 0.35 ]
					]}
					lineWidth={3}
					color="cyan"
					{...stencil}>
				</Line>
				<group position={[ 0, -1.1, 1.5 ]}>
					<Bust
						position={[ 0, 0.6, 0 ]}
						rotation={[ 0, -5 * Math.PI / 18, 0 ]}
						stencil={stencil}
					/>
					<Bust
						position={[ 0.02, 0.6, -0.02 ]}
						rotation={[ 0, -5.1 * Math.PI / 18, 0 ]}
						material={normalMat}
					/>
					<Column
						position={[ 0, -0.9, 0 ]}
						rotation={[ 0, Math.PI / 2, 0 ]}
						scale={[ 2.75, 2.63, 2.75 ]}
						stencil={stencil}
					/>
				</group>
				{palms.map(({ posX, rotY }, i) => (
					<Palm
						key={i}
						position={[ posX, -1.975, 1 - Math.floor(i / 2) ]}
						rotation={[ 0, rotY, 0 ]}
						scale={0.048}
						material={pinkPalmMat}
					/>
				))}
				<Billboard position={[ -0.25, 0, 1.975 ]}>
					<Plane args={[ 1.5, 1.0625 ]}>
						<meshBasicMaterial
							transparent={true}
							map={paintPng}
							{...stencil}
						/>
					</Plane>
				</Billboard>
				<Plane
					args={[ 3.95, 2.925 ]}
					position={[ 0, 0, -1.975 ]}>
					<meshBasicMaterial map={bgMap} {...stencil}/>
				</Plane>
			</group>
		</group>
	)
}