/* eslint react/no-unknown-property: 0 */
import { Texture } from "three"
import { Triplet } from "@react-three/cannon"

import type { PortalSceneProps } from "../../../types"

import { OutrunSun } from "./OutrunSun"
import { DeLorean } from "./DeLorean"
import { Terrain } from "./Terrain"
import { Diorama } from "../../Diorama"
import { CustomCubeCamera } from "../../CustomCubeCamera"

export function Outrun({ stencil, enabled = true, ...props }: PortalSceneProps) {
	return (
		<group {...props}>
			<group visible={enabled}>
				<Diorama stencil={stencil}/>
				<OutrunSun
					position={[ 0, -1, -1.9 ]}
					stencil={stencil}
				/>
				<Terrain
					position={[ 0, -1.975, 0 ]}
					stencil={stencil}
					enabled={enabled}
				/>
				<CustomCubeCamera
					rotation={[ 0, -((props.rotation || [ 0, 0, 0 ]) as Triplet)[1], 0 ]}
					resolution={512}
					stencil={stencil}
					frames={Infinity}>
					{(texture: Texture) => (
						<group rotation={props.rotation}>
							<DeLorean
								envMap={texture}
								stencil={stencil}
								position={[ 0, -1.975, 0.75 ]}
								rotation={[ 0, Math.PI, 0 ]}
								scale={0.3}
							/>
						</group>)}
				</CustomCubeCamera>
			</group>
		</group>
	)
}