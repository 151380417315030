/* eslint react/no-unknown-property: 0 */
import { Material, Mesh, MeshStandardMaterial } from "three"
import { GLTF } from "three-stdlib"
import { useGLTF } from "@react-three/drei"
import { useEffect } from "react"

type GLTFResult = GLTF & {
	nodes: {
		ROMANBUST_low: Mesh
	}
	materials: {
		ROMAN_BUST_4_LOW_POLY_1: MeshStandardMaterial
	}
}

type BustProps = JSX.IntrinsicElements[ "group" ] & {
	material?: Material,
	stencil?: any
}

export function Bust({ material, stencil, ...props }: BustProps) {
	const { nodes, materials } = useGLTF("/models/bust-comp.glb") as GLTFResult

	useEffect(() => {
		Object.assign(materials.ROMAN_BUST_4_LOW_POLY_1, stencil)
		materials.ROMAN_BUST_4_LOW_POLY_1.needsUpdate = true
	}, [ materials, stencil ])

	return (
		<group
			{...props}
			dispose={null}>
			<mesh
				geometry={nodes.ROMANBUST_low.geometry}
				material={material || materials.ROMAN_BUST_4_LOW_POLY_1}
				material-color="#cccccc"
			/>
		</group>
	)
}

useGLTF.preload("/models/bust-comp.glb")
