import { MeshStandardMaterial } from "three"
import { Plane } from "@react-three/drei"

import { StencilProps } from "../types"

import { Portal } from "./Portal"

export type PortalSide = "front" | "back" | "right" | "left" | "top" | "bottom"

type PortalTransform = {
	position: (size: number) => [ number, number, number ],
	rotation?: [ number, number, number ]
}

const portals: Record<PortalSide, PortalTransform> = {
	front: {
		position: (size: number) => ([ 0, 0, size / 2 ]),
		rotation: undefined
	},
	back: {
		position: (size: number) => ([ 0, 0, -size / 2 ]),
		rotation: [ 0, Math.PI, 0 ]
	},
	right: {
		position: (size: number) => ([ size / 2, 0, 0 ]),
		rotation: [ 0, Math.PI / 2, 0 ]
	},
	left: {
		position: (size: number) => ([ -size / 2, 0, 0 ]),
		rotation: [ 0, -Math.PI / 2, 0 ]
	},
	top: {
		position: (size: number) => ([ 0, size / 2, 0 ]),
		rotation: [ -Math.PI / 2, 0, 0 ]
	},
	bottom: {
		position: (size: number) => ([ 0, -size / 2, 0 ]),
		rotation: [ Math.PI / 2, 0, 0 ]
	}
}

const capMat = new MeshStandardMaterial({ color: "black" })

type PortalScene = (stencil: StencilProps) => JSX.Element

type PortalBoxProps = JSX.IntrinsicElements[ "group" ] & {
	size?: number,
	scenes: Partial<Record<PortalSide, PortalScene | undefined>>
}

export function PortalBox({ size = 4, scenes, ...props }: PortalBoxProps) {
	return (
		<group {...props}>
			{Object.entries(scenes).map(([ side, scene ], i) => {
				const { position, rotation } = portals[ side as PortalSide ]
				return scene && (
					<Portal
						key={side}
						stencilId={i + 1}
						size={size}
						position={position(size)}
						rotation={rotation}>
						{(stencil: any) => (
							scene(stencil)
						)}
					</Portal>
				)
			})}
			{Object.entries(portals)
				.filter(([ side ]) => !scenes[ side as PortalSide ])
				.map(([ side, { position, rotation } ]) => (
					<Plane
						key={side}
						args={[ size, size ]}
						position={position(size)}
						rotation={rotation}
						material={capMat}
					/>
				))
			}
		</group>
	)
}