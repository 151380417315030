/* eslint react/no-unknown-property: 0 */
import { useEffect, useMemo } from "react"
import { ShaderMaterial } from "three"
import { Circle, GradientTexture, Plane } from "@react-three/drei"

import { createGradientShader } from "../../../utils"

const ringMat = createGradientShader({
	colors: [ "red 0.5", "blue 0" ],
	direction: "radial",
	center: [ 0.5, 0.25 ]
})

const stripedSunMat = new ShaderMaterial({
	uniforms: {
		thickness: { value: 0.1 }
	},
	vertexShader: `
	varying vec2 vUv;

	void main() {
		vUv = uv;
		gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
	}
	`,
	fragmentShader: `
	uniform float thickness;

	varying vec2 vUv;

	void main() {
		gl_FragColor = vec4(0.0);
		if (vUv.y > 0.1 && vUv.y < 0.5) {
			float y = 25.0 * (vUv.y - 0.1);
			if (mod(y, 2.0) < 1.2) {
				gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
			}
		}
	}
	`,
	transparent: true
})

type OutrunSunProps = JSX.IntrinsicElements[ "group" ] & {
	stencil?: any
}

export function OutrunSun({ stencil, ...props }: OutrunSunProps) {

	const [ clonedRingMat, clonedStripedSunMat ] = useMemo(() => ([
		ringMat.clone(),
		stripedSunMat.clone()
	]), [])
	useEffect(() => {
		Object.assign(clonedRingMat, stencil)
		clonedRingMat.needsUpdate = true
		Object.assign(clonedStripedSunMat, stencil)
		clonedStripedSunMat.needsUpdate = true
	}, [ stencil, clonedRingMat, clonedStripedSunMat ])

	return (
		<group {...props}>
			<Circle
				args={[ 1, 64 ]}
				rotation={[ 0, 0, Math.PI ]}>
				<meshStandardMaterial
					transparent={true}
					{...stencil}>
					<GradientTexture
						stops={[ 0, 0.33, 0.5, 0.6, 1 ]}
						colors={[ "magenta", "red", "red", "orange", "yellow" ]}
					/>
				</meshStandardMaterial>
			</Circle>
			<Circle
				args={[ 1, 64 ]}
				position={[ 0, 0, 0.01 ]}
				material={clonedStripedSunMat}
			/>
			<Plane
				args={[ 4, 4 ]}
				position={[0, 1, -0.01 ]}
				material={clonedRingMat}
			/>
		</group>
	)
}