/* eslint react/no-unknown-property: 0 */
import { useEffect } from "react"
import { Material, Mesh, MeshStandardMaterial } from "three"
import { GLTF } from "three-stdlib"
import { useGLTF } from "@react-three/drei"

type GLTFResult = GLTF & {
	nodes: {
		mesh_0: Mesh
	}
	materials: {
		[ "Scene_-_Root" ]: MeshStandardMaterial
	}
}

type PalmProps = JSX.IntrinsicElements[ "group" ] & {
	material?: Material,
	stencil?: any
}

export function Palm({ material, stencil, ...props }: PalmProps) {
	const { nodes, materials } = useGLTF("/models/palm-comp.glb") as GLTFResult

	useEffect(() => {
		Object.assign(materials[ "Scene_-_Root" ], stencil)
		materials[ "Scene_-_Root" ].needsUpdate = true
	}, [ materials, stencil ])

	return (
		<group {...props} dispose={null}>
			<group rotation={[-Math.PI / 2, 0, 0]}>
				<mesh
					geometry={nodes.mesh_0.geometry}
					material={material || materials[ "Scene_-_Root" ]}
				/>
			</group>
		</group>
	)
}

useGLTF.preload("/models/palm-comp.glb")
