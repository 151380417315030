/* eslint react/no-unknown-property: 0 */
import { Ocean } from "react-three-ocean"

import { useEffect, useMemo } from "react"
import { MeshNormalMaterial, MeshStandardMaterial } from "three"
import type { PortalSceneProps } from "../../../types"

import { Diorama } from "../../Diorama"
import { Link } from "./Link"

export function Links({ stencil, enabled = true, ...props }: PortalSceneProps) {
	const [ normalMat, cyanMat ] = useMemo(() => ([
		new MeshNormalMaterial(),
		new MeshStandardMaterial({ color: "cyan" })
	]), [])

	useEffect(() => {
		Object.assign(normalMat, stencil)
		normalMat.needsUpdate = true
		Object.assign(cyanMat, stencil)
		cyanMat.needsUpdate = true
	}, [ normalMat, cyanMat, stencil ])

	return (
		<group
			{...props}
			visible={enabled}>
			<Diorama stencil={stencil}/>
			<Link
				position={[ -1.5, 0, 0 ]}
				scale={0.4}
				text="LATEST"
				link="https://cantofornia.com"
				materials={[ normalMat, cyanMat ]}
				visible={enabled}
			/>
			<Link
				position={[ -1.5, -1.05, 0 ]}
				scale={0.4}
				text="CONTACT"
				link={() => (window.location.href = "mailto:grasping@straws.dev")}
				materials={[ normalMat, cyanMat ]}
				visible={enabled}
			/>
			<Ocean
				position={[ 0, -1.99, 0 ]}
				dimensions={[ 4, 4 ]}
				distortionScale={3.7}
				size={8}
				normals="https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/waternormals.jpg">
				{(water: any) => {
					Object.assign(water.material, stencil)
					water.material.needsUpdate = true
					return null
				}}
			</Ocean>
		</group>
	)
}